import toAxiosError from "./toAxiosError";

function handleError(error: unknown) {
  const axiosError = toAxiosError(error);
  if (axiosError?.isAxiosError) {
    const status = axiosError.response?.status;
    switch (status) {
      case 400:
      case 404:
      case 500:
        throw new Error(String(status));
      default: {
        throw new Error("default");
      }
    }
  }
}

export default handleError;
