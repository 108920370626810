import React from "react";
import Cleave from "cleave.js/react";

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function MaskedCreditCardInput(props: Props) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      ref={(ref: any) => {
        if (ref && ref.inputElement) {
          inputRef(ref.inputElement);
        }
        return null;
      }}
      options={{
        creditCard: true,
        creditCardStrictMode: true,
      }}
      {...rest}
    />
  );
}

export default MaskedCreditCardInput;
