import { Control, Controller } from "react-hook-form";
import { RadioGroup } from "@material-ui/core";
import React, { PropsWithChildren } from "react";
import { FormFieldType } from "../types/formField";
import { CardDetails } from "../types/CardDetails";

type PropsType = {
  field: FormFieldType;
  defaultValue: string;
  control: Control<CardDetails>;
};

function FbRadioGroup({
  field,
  defaultValue,
  control,
  children,
}: PropsWithChildren<PropsType>) {
  return (
    <Controller
      name={field.name}
      defaultValue={defaultValue}
      control={control}
      render={({ name, value, onChange, onBlur }) => (
        <RadioGroup
          row
          aria-label={name}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
        >
          {children}
        </RadioGroup>
      )}
    />
  );
}

export default FbRadioGroup;
