import React, { PropsWithChildren } from "react";
import { Grid, Box } from "@material-ui/core";

function ErrorPage({ children }: PropsWithChildren<{}>) {
  return (
    <Box m={3}>
      <Grid container item alignItems="center" direction="column">
        {children}
      </Grid>
    </Box>
  );
}

export default ErrorPage;
