import { ErrorTypes } from "../../api/client";

export default {
  translation: {
    amount: "Bedrag",
    paymentMethod: "Betaal methode",
    choosePayment: "Kies Betaal methode",
    cardHolderName: "Naam op de kaart",
    brand: "Kaart",
    cardNumber: "Kaartnummer",
    securityCode: "Veiligheidsnummer",
    expiryMonth: "Maand vervaldatum",
    expiryYear: "Jaar Vervaldatum",
    expiryDate: "Vervaldatum",
    "Required field": "Verplicht veld",
    "Input is too long": "Input is te lang",
    "Input is too short": "Input is te kort",
    "The expiry date is incorrect": "De vervaldatum is onjuist",
    "The date should be in the future": "De datum moet in de toekomst zijn",
    "You can retry by clicking below":
      "U kunt opnieuw proberen door hieronder te klikken",
    Reset: "Reset",
    Cancel: "Annuleer",
    Submit: "Verstuur",
    "Session expired": "Sessie verlopen",
    "Merchant failed to process the payment request":
      "Handelaar heeft het betalingsverzoek niet kunnen verwerken",
    "Your payment was successful": "Uw betaling was succesvol",
    "No methods available for selection": "No methods available for selection",
    "500": "Something went wrong",
    "404": "Sessie verlopen",
    "400": "Vul het formulier in en probeer het opnieuw",
    [ErrorTypes.CardNumber]: "Ongeldig kaart nummer",
    [ErrorTypes.CardBrand]: "Ongeldig kaart type",
    [ErrorTypes.ExpiryDate]: "Ongeldige vervaldatum",
    [ErrorTypes.Cvv]: "Ongeldig CVV/CVC",
    [ErrorTypes.CardHolderName]: "Ongeldige naam op de kaart",
    Redirect: "Doorzenden",
    "You will be redirected shortly": "U wordt binnenkort doorgestuurd",
    "If this does not work click below":
      "Als dit niet werkt, klik dan hieronder",
    YYYY: "YYYY",
    MM: "MM",
    "No method has been selected on the select method page":
      "No method has been selected on the select method page",
    "No year found that matches the key provided":
      "No year found that matches the key provided",
  },
};
