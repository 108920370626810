import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Controller, useForm } from "react-hook-form";
import { isFuture, isValid } from "date-fns";
import promiseDebounce from "awesome-debounce-promise";
import {
  AmountOfMoney,
  Items,
  Method,
  PageResponse,
  ProvideDetailsRequest,
} from "../api/client";
import usePageResponse from "../hooks/use-page-response";
import ErrorPage from "./ErrorPage";
import FbInput from "../components/FbInput";
import FbFieldForm from "../components/FbFormField";
import ErrorText from "../components/ErrorText";
import LanguageSwitch from "../components/LanguageSwitch";
import { useTranslation } from "react-i18next";
import {
  fieldNames,
  formFieldErrorMap,
  FormFieldType,
} from "../types/formField";
import FbRadioGroup from "../components/FbRadioGroup";
import CreditCardLogo from "../components/CreditCardLogo";
import { CardDetails } from "../types/CardDetails";
import { makeStyles } from "@material-ui/core";
import MaskedCreditCardInput from "../components/MaskedCreditCardInput";
import MaskedExpiryInput from "../components/MaskedExpiryInput";
import CurrencyConfig from "../resources/currency-config.json";

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
});

type PropsType = {
  hostedPagesId: string;
  pageResponse: PageResponse | undefined;
};

function MethodDetailsPage({ hostedPagesId, pageResponse }: PropsType) {
  const { t, ready } = useTranslation("translation", { useSuspense: false });
  const {
    provideDetails: [mutate, { isError, reset, isLoading }],
    bINLookup: [bINLookup],
    restart: [restart],
  } = usePageResponse(hostedPagesId);
  const { register, handleSubmit, errors, setError, control, watch, setValue } =
    useForm<CardDetails>();
  const [methods, setMethods] = useState<Method[]>([]);

  useEffect(() => {
    if (methods.length > 0) {
      setValue("brand", methods[0].name);
    }
  }, [methods, setValue]);

  const showError = pageResponse?.pageDetails?.showError;
  useEffect(() => {
    if (showError && formFieldErrorMap.has(showError)) {
      const errorField = formFieldErrorMap.get(showError);
      const field = fieldNames.find((field) => field === errorField);
      if (field) {
        setError(field, {
          type: `${showError}`,
          message: t(showError),
        });
      }
    }
  }, [showError, setError, t]);
  const classes = useStyles();

  const debounceBINLookup = promiseDebounce(bINLookup, 500);

  const cardNumberField: FormFieldType = {
    label: t("cardNumber"),
    name: "cardNumber",
    autocomplete: "cc-number",
    rules: {
      required: { value: true, message: t("Required field") },
      minLength: { value: 13, message: t("Input is too short") },
    },
  };
  const cardBrandField: FormFieldType = {
    label: t("brand"),
    name: "brand",
    rules: {
      required: { value: true, message: t("Required field") },
    },
  };

  const formFields: Array<FormFieldType> = [
    {
      label: t("cardHolderName"),
      name: "cardHolderName",
      autocomplete: "cc-name",
      rules: { required: { value: true, message: t("Required field") } },
    },
  ];
  const securityCodeField: FormFieldType = {
    label: t("securityCode"),
    name: "securityCode",
    type: "tel",
    autocomplete: "cc-csc",
    rules: {
      required: { value: true, message: t("Required field") },
      minLength: { value: 3, message: t("Input is too short") },
      maxLength: { value: 4, message: t("Input is too long") },
    },
  };

  const handleBINLookup = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    updateFieldValue?: (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void
  ) => {
    try {
      if (updateFieldValue) {
        updateFieldValue(event);
      }
      if (!pageResponse?.pageDetails?.iinLookupAvailable) {
        return;
      }

      const input = event.currentTarget.value;
      if (input && input.length > 6) {
        const bINLookupResponse = await debounceBINLookup({
          hostedPagesId,
          iINLookupRequest: { iin: input.replace(/\s/g, "").substring(0, 6) },
        });
        if (bINLookupResponse) {
          setMethods(
            bINLookupResponse.filter((method) => method && method.name)
          );
        }
      }
      return;
    } catch (e) {
      throw new Error(e);
    }
  };

  const provideDetails = async (cardDetails: CardDetails) => {
    if (!pageResponse?.pageDetails?.method) {
      throw Error(t("No method has been selected on the select method page"));
    }

    const [m, y] = cardDetails.expiryDate.split("/");
    const month = Number(m) - 1 + "";
    const year = `20${y}`;
    if (Number(month) > 11) {
      setError("expiryDate", {
        type: "manual_expiry_date_month_or_year_undefined",
        message: t("The expiry date is incorrect"),
      });
      return;
    }

    const date = new Date(Number(year), Number(month) + 1); //Added a month to the date so expiry dates of the current month are still valid.
    if (!isValid(date)) {
      setError("expiryDate", {
        type: "manual_expiry_date_invalid",
        message: t("The expiry date is incorrect"),
      });
      return;
    }
    if (!isFuture(date)) {
      setError("expiryDate", {
        type: "manual_expiry_date_not_in_future",
        message: t("The date should be in the future"),
      });
      return;
    }
    const { method } = pageResponse.pageDetails;
    const provideDetailsRequest: ProvideDetailsRequest = {
      method: String(method.name),
      cardDetails: {
        cardHolderName: cardDetails.cardHolderName,
        securityCode: cardDetails.securityCode,
        cardNumber: cardDetails.cardNumber.split(" ").join(""),
        expiryYear: y,
        expiryMonth: m,
        brand: pageResponse.pageDetails.iinLookupAvailable
          ? cardDetails.brand
          : method.name,
      },
      threedsData: {
        colorDepth: window.screen.colorDepth,
        javaEnabled: navigator.javaEnabled(),
        screenHeight: String(window.screen.height),
        screenWidth: String(window.screen.width),
        timezoneOffset: String(new Date().getTimezoneOffset()),
      },
    };

    await mutate({ hostedPagesId, provideDetailsRequest });
  };

  if (isError) {
    return (
      <ErrorPage>
        <Typography component="h6" variant="h6">
          {t("You can retry by clicking below")}
        </Typography>
        <Button onClick={reset}>{t("Reset")}</Button>
      </ErrorPage>
    );
  }

  if (!ready) {
    return <CircularProgress />;
  }

  const expiry: FormFieldType = {
    label: t("expiryDate"),
    name: "expiryDate",
    autocomplete: "cc-exp",
    placeholder: t("MM/YY"),
    rules: {
      required: { value: true, message: t("Required field") },
      minLength: { value: 4, message: t("Input is too short") },
      maxLength: { value: 4, message: t("Input is too long") },
    },
  };

  const locale =
    pageResponse?.eagleStyle?.language ||
    pageResponse?.pageDetails?.language ||
    "en-US";

  function convertAmountOfMoney(amountOfMoney: AmountOfMoney) {
    if (
      amountOfMoney.currencyCode != null &&
      CurrencyConfig.currency.codeWithoutCents.includes(
        amountOfMoney.currencyCode.toUpperCase()
      )
    ) {
      return Number(amountOfMoney.amount);
    } else {
      return Number(amountOfMoney.amount) / 100;
    }
  }

  return (
    <Box m={2}>
      <form onSubmit={handleSubmit(provideDetails)}>
        <Grid item xs={12}>
          <Box display="none" justifyContent="flex-end" pb={2}>
            <LanguageSwitch
              control={control}
              initialLanguage={locale}
            ></LanguageSwitch>
          </Box>
        </Grid>
        <Grid container spacing={1}>
          {pageResponse?.pageDetails?.amountOfMoney && (
            <Grid item xs={6} sm={2}>
              <Typography variant="h6" component="h1" color="textSecondary">
                {t("amount")}
              </Typography>
            </Grid>
          )}
          {pageResponse?.pageDetails?.amountOfMoney && (
            <Grid item xs={6} sm={6}>
              <Typography variant="h6" component="h1" color="textSecondary">
                {Number(
                  convertAmountOfMoney(pageResponse?.pageDetails?.amountOfMoney)
                ).toLocaleString(locale, {
                  style: "currency",
                  currency:
                    pageResponse?.pageDetails?.amountOfMoney?.currencyCode,
                })}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <FbFieldForm formField={cardNumberField}>
              <Controller
                name={cardNumberField.name}
                defaultValue={""}
                control={control}
                render={({ onChange, onBlur, value, name }) => (
                  <FbInput
                    id={name}
                    name={name}
                    onChange={(e) => handleBINLookup(e, onChange)}
                    onBlur={onBlur}
                    value={value}
                    inputComponent={MaskedCreditCardInput as any}
                    endAdornment={
                      pageResponse?.pageDetails?.method?.name !== Items.Card ? (
                        <InputAdornment position="end">
                          <CreditCardLogo
                            src={pageResponse?.pageDetails?.method?.logoUrl}
                            alt={pageResponse?.pageDetails?.method?.displayName}
                          />
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <CreditCardLogo
                            src={
                              methods.find(
                                (method) => method.name === watch("brand")
                              )?.logoUrl
                            }
                            alt={
                              methods.find(
                                (method) => method.name === watch("brand")
                              )?.displayName
                            }
                          />
                        </InputAdornment>
                      )
                    }
                  />
                )}
              />
              <ErrorText formField={cardNumberField} errors={errors} />
              {methods.length <= 0 ||
              !pageResponse?.pageDetails?.iinLookupAvailable ? (
                <ErrorText formField={cardBrandField} errors={errors} />
              ) : null}
            </FbFieldForm>
            {methods && methods.length > 0 && (
              <FbRadioGroup
                field={cardBrandField}
                defaultValue={
                  methods &&
                  methods.length > 0 &&
                  pageResponse?.pageDetails?.method?.name === Items.Card
                    ? methods[0].name
                    : String(pageResponse?.pageDetails?.method?.name)
                }
                control={control}
              >
                {methods.map((brand) => (
                  <FormControlLabel
                    aria-label={t(cardBrandField.name)}
                    key={brand.name}
                    value={brand.name}
                    control={<Radio color="primary" />}
                    label={
                      <Grid container>
                        <CreditCardLogo
                          src={brand.logoUrl}
                          alt={brand.displayName}
                        />
                      </Grid>
                    }
                  />
                ))}
              </FbRadioGroup>
            )}
          </Grid>

          <Grid item sm={8} xs={12}>
            <Box mr={2}>
              <FbFieldForm formField={expiry}>
                <Controller
                  name={expiry.name}
                  defaultValue={""}
                  control={control}
                  placeholder={expiry.placeholder}
                  render={({ onChange, onBlur, value, name }) => (
                    <FbInput
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder={expiry.placeholder}
                      inputComponent={MaskedExpiryInput as any}
                    />
                  )}
                />
                <ErrorText formField={expiry} errors={errors} />
              </FbFieldForm>
            </Box>
          </Grid>

          <Grid item sm={4} xs={12} key={securityCodeField.name}>
            <FbFieldForm formField={securityCodeField}>
              <FbInput
                id={securityCodeField.name}
                name={securityCodeField.name}
                inputRef={register(securityCodeField.rules)}
                type={securityCodeField.type}
                autoComplete={securityCodeField.autocomplete}
              />
              <ErrorText formField={securityCodeField} errors={errors} />
            </FbFieldForm>
          </Grid>

          {formFields.map((formField) => (
            <Grid item xs={12} key={formField.name}>
              <FbFieldForm formField={formField}>
                <FbInput
                  id={formField.name}
                  name={formField.name}
                  inputRef={register(formField.rules)}
                  type={formField.type}
                  autoComplete={formField.autocomplete}
                />
                <ErrorText formField={formField} errors={errors} />
              </FbFieldForm>
            </Grid>
          ))}
        </Grid>
        <Grid container item justify={"flex-end"}>
          <Box
            marginTop={3}
            marginRight={1}
            display={
              pageResponse?.pageDetails?.iinLookupAvailable ? "none" : "block"
            }
          >
            <Button
              variant="outlined"
              color="primary"
              type="reset"
              onClick={() => restart({ hostedPagesId })}
              disabled={isLoading}
            >
              {t("Cancel")}
            </Button>
          </Box>
          <Box marginTop={3}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.root}
              disabled={isLoading}
            >
              {t("Submit")}
            </Button>
          </Box>
        </Grid>
      </form>
    </Box>
  );
}

export default MethodDetailsPage;
