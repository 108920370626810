import React from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";
import Box from "@material-ui/core/Box";
import { Page } from "../api/client";
import MethodSelectionPage from "../Pages/MethodSelectionPage";
import MethodDetailsPage from "../Pages/MethodDetailsPage";
import usePageResponse from "../hooks/use-page-response";
import useHostedPagesId from "../hooks/use-hosted-pages-id";
import ErrorPage from "../Pages/ErrorPage";
import RedirectToThirdPartyPage from "../Pages/RedirectToThirdPartyPage";
import Pages from "./Pages";
import { AxiosError } from "axios";
import handleError from "../utils/handleError";
import { useTranslation } from "react-i18next";
import defaultStyling from "../utils/defaultStyling";

function toAxiosError(error: unknown): AxiosError | undefined {
  const possibleAxiosError = error as AxiosError;
  if (possibleAxiosError.isAxiosError) {
    return possibleAxiosError;
  }
  return undefined;
}

const theme = (overrides: Overrides) => {
  return createMuiTheme({
    overrides: {
      ...overrides,
    },
  });
};

function Layout() {
  const hostedPagesId = useHostedPagesId();
  const { t, ready } = useTranslation("translation", { useSuspense: false });
  const {
    pageResponse: { data: state, isError, isLoading, error },
    restart: [restart],
  } = usePageResponse(hostedPagesId);

  const ResetButton = () => (
    <Button onClick={() => restart({ hostedPagesId })}>{t("Reset")}</Button>
  );

  if (isError) {
    const axiosError = toAxiosError(error);
    if (axiosError?.isAxiosError) {
      handleError(axiosError);
    }
  }

  if (isLoading || !ready) {
    return <CircularProgress />;
  }

  const style = state?.eagleStyle?.css || defaultStyling();

  return (
    <MuiThemeProvider theme={() => theme(JSON.parse(style))}>
      <Pages activePage={state?.page}>
        <Box key={Page.SessionExpired}>
          <Typography component="h6" variant="h6">
            {t("Session expired")}
          </Typography>
        </Box>
        <Box key={Page.MerchantFailure}>
          <Typography component="h6" variant="h6">
            {t("Merchant failed to process the payment request")}
          </Typography>
        </Box>
        <RedirectToThirdPartyPage
          key={Page.MerchantSuccess}
          redirectUrl={state?.pageDetails?.action?.actionDetails?.url || ""}
        />
        <RedirectToThirdPartyPage
          key={Page.RedirectToThirdParty}
          redirectUrl={state?.pageDetails?.action?.actionDetails?.url || ""}
        />
        <Box key={Page.FlowbirdFailure}>
          <ErrorPage>
            <Typography component="h6" variant="h6">
              {t("Merchant failed to process the payment request")}
            </Typography>
            <ResetButton />
          </ErrorPage>
        </Box>
        <MethodSelectionPage
          key={Page.MethodSelection}
          hostedPagesId={hostedPagesId}
          pageResponse={state}
        />
        <MethodDetailsPage
          key={Page.MethodDetails}
          hostedPagesId={hostedPagesId}
          pageResponse={state}
        />
      </Pages>
    </MuiThemeProvider>
  );
}

export default Layout;
