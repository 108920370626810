import { AxiosPromise } from "axios";
import { PageResponse } from "../api/client";

async function getDataFromAxiosResponse(
  axiosPromise: AxiosPromise<PageResponse>
) {
  const axiosResponse = await axiosPromise;
  return axiosResponse.data;
}

export default getDataFromAxiosResponse;
