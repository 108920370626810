const styling = {
  MuiContainer: {
    root: {
      height: "100vh",
      backgroundColor: "#f2f2f2",
    },
  },
  MuiTypography: {
    root: {
      fontFamily: "'Trebuchet MS', Helvetica, sans-serif",
      color: "#333333",
    },
  },
  MuiListItem: {
    button: {
      marginBottom: "20px",
      padding: "20px",
      border: "1px solid #003366",
      borderRadius: "2px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.03)",
      },
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: "20px",
      fontFamily: "'Trebuchet MS', Helvetica, sans-serif",
      color: "#333333",
      "&.Mui-focused": {
        color: "#005FA5",
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: "2px",
      "&$focused $notchedOutline": {
        borderColor: "#005FA5",
      },
    },
    input: {
      padding: "12px",
      backgroundColor: "#ffffff",
      borderRadius: "2px",
    },
    focused: {},
    notchedOutline: {
      borderColor: "#003366",
    },
    adornedEnd: {
      backgroundColor: "#ffffff",
    },
  },
  MuiRadio: {
    root: {
      color: "#005FA5",
    },
    colorPrimary: {
      "&.Mui-checked": {
        color: "#005FA5",
      },
    },
  },
  MuiCircularProgress: {
    colorPrimary: {
      color: "#005FA5",
      marginTop: "100px",
    },
  },
  MuiButton: {
    containedSecondary: {
      padding: "6px 15px",
      borderRadius: "2px",
      fontSize: "16px",
      fontFamily: "'Trebuchet MS', Helvetica, sans-serif",
      color: "#ffffff",
      backgroundColor: "#003366",
      border: "1px solid #003366",
      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#005FA5",
        borderColor: "#005FA5",
      },
    },
    outlinedPrimary: {
      padding: "6px 15px",
      borderRadius: "2px",
      fontSize: "16px",
      fontFamily: "'Trebuchet MS', Helvetica, sans-serif",
      color: "#333333",
      border: "1px solid #003366",
      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#005FA5",
        borderColor: "#005FA5",
      },
    },
  },
};

function defaultStyling() {
  return JSON.stringify(styling);
}

export default defaultStyling;
