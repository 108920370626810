import { AxiosError } from "axios";

function toAxiosError(error: unknown) {
  const possibleAxiosError = error as AxiosError;
  if (possibleAxiosError.isAxiosError) {
    return possibleAxiosError;
  }
  return undefined;
}

export default toAxiosError;
