import { ErrorTypes } from "../../api/client";

export default {
  translation: {
    amount: "Montant",
    paymentMethod: "Mode de paiement",
    choosePayment: "Choisissez le mode de paiement",
    cardHolderName: "Nom du titulaire",
    brand: "Logo d'acceptation",
    cardNumber: "Numéro de carte",
    securityCode: "Cryptogramme visuel",
    expiryMonth: "Mois de fin de validité",
    expiryYear: "Année de fin de validité",
    expiryDate: "Date de fin de validité",
    "Required field": "Champ obligatoire",
    "Input is too long": "L'entrée est trop longue",
    "Input is too short": "L'entrée est trop courte",
    "The expiry date is incorrect": "La date d'expiration est incorrecte",
    "The date should be in the future": "La date doit être dans le futur",
    "You can retry by clicking below":
      "Vous pouvez réessayer en cliquant ci-dessous",
    Reset: "Effacer",
    Cancel: "Annuler",
    Submit: "Envoyer",
    "Session expired": "Session Expirée",
    "Merchant failed to process the payment request":
      "Le commerçant n'a pu traité la demande de paiement",
    "Your payment was successful": "Votre paiement a été effectué avec succès",
    "No methods available for selection": "No methods available for selection",
    "500": "Something went wrong",
    "404": "Session Expirée",
    "400": "Veuillez remplir le formulaire et réessayer",
    [ErrorTypes.CardNumber]: "Numéro de carte invalide",
    [ErrorTypes.CardBrand]: "Type de carte invalide",
    [ErrorTypes.ExpiryDate]: "Date d'expiration invalide",
    [ErrorTypes.Cvv]: "CVV/CVC invalide",
    [ErrorTypes.CardHolderName]: "Nom du titulaire invalide",
    Redirect: "Rediriger",
    "You will be redirected shortly": "Vous serez bientôt redirigé",
    "If this does not work click below":
      "Si cela ne fonctionne pas, cliquez ci-dessous",
    YYYY: "YYYY",
    MM: "MM",
    "No method has been selected on the select method page":
      "No method has been selected on the select method page",
    "No year found that matches the key provided":
      "No year found that matches the key provided",
  },
};
