import React from "react";
import { Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = { redirectUrl: string };

function RedirectToThirdPartyPage({ redirectUrl }: Props) {
  const { t } = useTranslation();
  window.location.replace(redirectUrl);

  return (
    <React.Fragment>
      <Typography component="h5" variant="h5">
        {t("You will be redirected shortly")}
      </Typography>
      <Typography component="h6" variant="h6">
        {t("If this does not work click below")}
      </Typography>
      {redirectUrl ? (
        <Link href={redirectUrl}>
          <Typography component="h5" variant="h5">
            {t("Redirect")}
          </Typography>
        </Link>
      ) : null}
    </React.Fragment>
  );
}

export default RedirectToThirdPartyPage;
