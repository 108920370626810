import React from "react";
import { Method, PageResponse } from "../api/client";
import usePageResponse from "../hooks/use-page-response";
import ErrorPage from "./ErrorPage";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CreditCardLogo from "../components/CreditCardLogo";

type PropTypes = {
  hostedPagesId: string;
  pageResponse: PageResponse | undefined;
};

function MethodSelectionPage({ hostedPagesId, pageResponse }: PropTypes) {
  const {
    selectMethod: [mutate, { isError, reset }],
  } = usePageResponse(hostedPagesId);
  const { t, ready } = useTranslation("translation", { useSuspense: false });
  if (!pageResponse?.pageDetails?.methods) {
    throw new Error(t("No methods available for selection"));
  }

  const selectMethod = async (method: Method) => {
    await mutate({
      hostedPagesId: hostedPagesId,
      selectMethodRequest: { method: method.name },
    });
  };

  if (isError) {
    return (
      <ErrorPage>
        <Typography component="h6" variant="h6">
          {t("You can retry by clicking below")}
        </Typography>
        <Button onClick={reset}>{t("Reset")}</Button>
      </ErrorPage>
    );
  }

  if (!ready) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography
        variant="h6"
        component="h1"
        color="textSecondary"
        gutterBottom
      >
        {t("choosePayment")}
      </Typography>
      <List component={"nav"}>
        {pageResponse?.pageDetails?.methods?.map((method) => (
          <Paper key={method.name}>
            <ListItem onClick={() => selectMethod(method)} button>
              <ListItemIcon>
                <CreditCardLogo
                  src={method.logoUrl}
                  alt={`${t("paymentMethod")} ${method.displayName}`}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography variant={"body1"}>{method.displayName}</Typography>
              </ListItemText>
            </ListItem>
          </Paper>
        ))}
      </List>
    </Box>
  );
}

export default MethodSelectionPage;
