import { SelectMethodRequest } from "../api/client";
import api from "../api";

type SelectMethodPayload = {
  hostedPagesId: string;
  selectMethodRequest: SelectMethodRequest;
};

function selectMethod({
  hostedPagesId,
  selectMethodRequest,
}: SelectMethodPayload) {
  return api
    .selectMethod(hostedPagesId, selectMethodRequest)
    .then((r) => r.data);
}

export default selectMethod;
