import { ProvideDetailsRequest } from "../api/client";
import api from "../api";

type ProvideDetailsPayload = {
  hostedPagesId: string;
  provideDetailsRequest: ProvideDetailsRequest;
};

function provideDetails({
  hostedPagesId,
  provideDetailsRequest,
}: ProvideDetailsPayload) {
  return api
    .provideDetails(hostedPagesId, provideDetailsRequest)
    .then((r) => r.data);
}

export default provideDetails;
