import api from "../api";
import { IINLookupRequest } from "../api/client";

type IINLookupPayload = {
  hostedPagesId: string;
  iINLookupRequest: IINLookupRequest;
};

async function bINLookup({
  hostedPagesId,
  iINLookupRequest,
}: IINLookupPayload) {
  const r = await api.bINLookup(hostedPagesId, iINLookupRequest);
  return r.data;
}

export default bINLookup;
