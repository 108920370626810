import React from "react";
import Cleave from "cleave.js/react";

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function MaskedExpiryInput(props: Props) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      ref={(ref: any) => {
        if (ref && ref.inputElement) {
          inputRef(ref.inputElement);
        }
        return null;
      }}
      options={{
        date: true,
        datePattern: ["m", "y"],
      }}
      {...rest}
    />
  );
}

export default MaskedExpiryInput;
