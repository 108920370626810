import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";
import fr from "./translations/fr";
import nl from "./translations/nl";

const resources = {
  en,
  fr,
  nl,
};

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV !== "production",
  resources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
