import { useLocation } from "react-router-dom";

function useHostedPagesId() {
  const queryParams = new URLSearchParams(useLocation().search);
  const hostedPagesId = queryParams.get("hostedPagesId");
  if (!hostedPagesId) {
    throw new Error("No hosted pages id is present");
  }
  return hostedPagesId;
}

export default useHostedPagesId;
