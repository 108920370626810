import { Box, FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import React, { PropsWithChildren } from "react";
import { FormFieldType } from "../types/formField";

type FbFormFieldProps = {
  formField: FormFieldType;
};

function FbFormField({
  formField,
  children,
}: PropsWithChildren<FbFormFieldProps>) {
  return (
    <Box marginTop={1}>
      <FormControl fullWidth>
        <InputLabel shrink htmlFor={formField.name}>
          {formField.label}
        </InputLabel>
        {children}
      </FormControl>
    </Box>
  );
}

export default FbFormField;
