import { FallbackProps } from "react-error-boundary";
import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function ErrorFallback({ error }: FallbackProps) {
  const { t } = useTranslation();
  return (
    <Typography component={"h2"} variant={"h6"} role="alert">
      {error?.message ? t(error.message) : null}
    </Typography>
  );
}

export default ErrorFallback;
