// tslint:disable
/**
 * Eagle Payment Processor Static Assets Backend API
 * The API that will be used by Eagle Static Assets
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * The action that needs to be taken
 * @export
 * @enum {string}
 */
export enum ActionType {
    Redirect = 'redirect'
}

/**
 * Object representing an amount of money
 * @export
 * @interface AmountOfMoney
 */
export interface AmountOfMoney {
    /**
     * Amount in human readable format.
     * @type {number}
     * @memberof AmountOfMoney
     */
    amount?: number;
    /**
     * ISO-4217 Currency Code
     * @type {string}
     * @memberof AmountOfMoney
     */
    currencyCode?: string;
}
/**
 * Object representing a styling template
 * @export
 * @interface EagleContextStyle
 */
export interface EagleContextStyle {
    /**
     * Identifier of the template
     * @type {string}
     * @memberof EagleContextStyle
     */
    templateId?: string;
    /**
     * Name of the template
     * @type {string}
     * @memberof EagleContextStyle
     */
    name?: string;
    /**
     * Description of the template
     * @type {string}
     * @memberof EagleContextStyle
     */
    description?: string;
    /**
     * The CSS to be used in this template
     * @type {string}
     * @memberof EagleContextStyle
     */
    css?: string;
    /**
     * The default language to use for the template
     * @type {string}
     * @memberof EagleContextStyle
     */
    language?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorTypes {
    CardBrand = 'invalid_card_brand',
    CardNumber = 'invalid_card_number',
    Cvv = 'invalid_cvv',
    ExpiryDate = 'invalid_expiry_date',
    CardHolderName = 'invalid_card_holder_name'
}

/**
 * Request containing the first 6 or 8 digits entered to perform a IIN lookup
 * @export
 * @interface IINLookupRequest
 */
export interface IINLookupRequest {
    /**
     * The card IIN
     * @type {string}
     * @memberof IINLookupRequest
     */
    iin: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Items {
    Card = 'card',
    AmericanExpress = 'american_express',
    CarteBancaire = 'carte_bancaire',
    DinersClub = 'diners_club',
    Jcb = 'jcb',
    Maestro = 'maestro',
    Mastercard = 'mastercard',
    MastercardDebit = 'mastercard_debit',
    Visa = 'visa',
    VisaDebit = 'visa_debit'
}

/**
 * 
 * @export
 * @interface Method
 */
export interface Method {
    /**
     * 
     * @type {Items}
     * @memberof Method
     */
    name: Items;
    /**
     * The display name for the method
     * @type {string}
     * @memberof Method
     */
    displayName: string;
    /**
     * The logo url
     * @type {string}
     * @memberof Method
     */
    logoUrl: string;
}
/**
 * The page that needs to be rendered
 * @export
 * @enum {string}
 */
export enum Page {
    MethodSelection = 'method_selection',
    MethodDetails = 'method_details',
    RedirectToThirdParty = 'redirect_to_third_party',
    MerchantSuccess = 'merchant_success',
    MerchantFailure = 'merchant_failure',
    FlowbirdFailure = 'flowbird_failure',
    SessionExpired = 'session_expired'
}

/**
 * Object containing details about an action that needs to be taken by the Static Assets
 * @export
 * @interface PageAction
 */
export interface PageAction {
    /**
     * 
     * @type {ActionType}
     * @memberof PageAction
     */
    actionType: ActionType;
    /**
     * 
     * @type {PageActionActionDetails}
     * @memberof PageAction
     */
    actionDetails: PageActionActionDetails;
}
/**
 * The details of the action
 * @export
 * @interface PageActionActionDetails
 */
export interface PageActionActionDetails {
    /**
     * The URL to redirect to
     * @type {string}
     * @memberof PageActionActionDetails
     */
    url: string;
}
/**
 * Object containing the page details
 * @export
 * @interface PageDetails
 */
export interface PageDetails {
    /**
     * The available methods
     * @type {Array<Method>}
     * @memberof PageDetails
     */
    methods?: Array<Method>;
    /**
     * 
     * @type {Method}
     * @memberof PageDetails
     */
    method?: Method;
    /**
     * Boolean to indicate whether IIN lookup is available
     * @type {boolean}
     * @memberof PageDetails
     */
    iinLookupAvailable?: boolean;
    /**
     * 
     * @type {PageAction}
     * @memberof PageDetails
     */
    action?: PageAction;
    /**
     * 
     * @type {AmountOfMoney}
     * @memberof PageDetails
     */
    amountOfMoney?: AmountOfMoney;
    /**
     * Language of the page
     * @type {string}
     * @memberof PageDetails
     */
    language?: string;
    /**
     * 
     * @type {ErrorTypes}
     * @memberof PageDetails
     */
    showError?: ErrorTypes;
}
/**
 * Response containing information needed to render the response
 * @export
 * @interface PageResponse
 */
export interface PageResponse {
    /**
     * 
     * @type {Page}
     * @memberof PageResponse
     */
    page?: Page;
    /**
     * 
     * @type {PageDetails}
     * @memberof PageResponse
     */
    pageDetails?: PageDetails;
    /**
     * 
     * @type {EagleContextStyle}
     * @memberof PageResponse
     */
    eagleStyle?: EagleContextStyle;
}
/**
 * Contains the provided details
 * @export
 * @interface ProvideDetailsRequest
 */
export interface ProvideDetailsRequest {
    /**
     * The selected method
     * @type {string}
     * @memberof ProvideDetailsRequest
     */
    method: string;
    /**
     * 
     * @type {ProvideDetailsRequestCardDetails}
     * @memberof ProvideDetailsRequest
     */
    cardDetails: ProvideDetailsRequestCardDetails;
    /**
     * 
     * @type {ProvideDetailsRequestThreedsData}
     * @memberof ProvideDetailsRequest
     */
    threedsData?: ProvideDetailsRequestThreedsData;
}
/**
 * Object containing card details
 * @export
 * @interface ProvideDetailsRequestCardDetails
 */
export interface ProvideDetailsRequestCardDetails {
    /**
     * The card brand
     * @type {string}
     * @memberof ProvideDetailsRequestCardDetails
     */
    brand: string;
    /**
     * The card number
     * @type {string}
     * @memberof ProvideDetailsRequestCardDetails
     */
    cardNumber: string;
    /**
     * The security code
     * @type {string}
     * @memberof ProvideDetailsRequestCardDetails
     */
    securityCode: string;
    /**
     * The expiry year
     * @type {string}
     * @memberof ProvideDetailsRequestCardDetails
     */
    expiryYear: string;
    /**
     * The expiry month
     * @type {string}
     * @memberof ProvideDetailsRequestCardDetails
     */
    expiryMonth: string;
    /**
     * The card holder name
     * @type {string}
     * @memberof ProvideDetailsRequestCardDetails
     */
    cardHolderName: string;
}
/**
 * Object containing browser information needed for 3D-Secure v2
 * @export
 * @interface ProvideDetailsRequestThreedsData
 */
export interface ProvideDetailsRequestThreedsData {
    /**
     * The color depth of the screen
     * @type {number}
     * @memberof ProvideDetailsRequestThreedsData
     */
    colorDepth: number;
    /**
     * The height of the screen
     * @type {string}
     * @memberof ProvideDetailsRequestThreedsData
     */
    screenHeight: string;
    /**
     * The width of the screen
     * @type {string}
     * @memberof ProvideDetailsRequestThreedsData
     */
    screenWidth: string;
    /**
     * Is java enabled on the browser. Result of navigator.javaEnabled
     * @type {boolean}
     * @memberof ProvideDetailsRequestThreedsData
     */
    javaEnabled: boolean;
    /**
     * The offset of the timezone compared to UTC.
     * @type {string}
     * @memberof ProvideDetailsRequestThreedsData
     */
    timezoneOffset: string;
}
/**
 * Contains the selected method
 * @export
 * @interface SelectMethodRequest
 */
export interface SelectMethodRequest {
    /**
     * The selected method
     * @type {string}
     * @memberof SelectMethodRequest
     */
    method: string;
}

/**
 * StaticAssetsBINLookupApi - axios parameter creator
 * @export
 */
export const StaticAssetsBINLookupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Performs a BIN lookup in the backend
         * @param {string} hostedPagesId 
         * @param {IINLookupRequest} iINLookupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bINLookup: async (hostedPagesId: string, iINLookupRequest: IINLookupRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostedPagesId' is not null or undefined
            if (hostedPagesId === null || hostedPagesId === undefined) {
                throw new RequiredError('hostedPagesId','Required parameter hostedPagesId was null or undefined when calling bINLookup.');
            }
            // verify required parameter 'iINLookupRequest' is not null or undefined
            if (iINLookupRequest === null || iINLookupRequest === undefined) {
                throw new RequiredError('iINLookupRequest','Required parameter iINLookupRequest was null or undefined when calling bINLookup.');
            }
            const localVarPath = `/iin-lookup/{hosted-pages-id}`
                .replace(`{${"hosted-pages-id"}}`, encodeURIComponent(String(hostedPagesId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof iINLookupRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(iINLookupRequest !== undefined ? iINLookupRequest : {}) : (iINLookupRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticAssetsBINLookupApi - functional programming interface
 * @export
 */
export const StaticAssetsBINLookupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Performs a BIN lookup in the backend
         * @param {string} hostedPagesId 
         * @param {IINLookupRequest} iINLookupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bINLookup(hostedPagesId: string, iINLookupRequest: IINLookupRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Method>>> {
            const localVarAxiosArgs = await StaticAssetsBINLookupApiAxiosParamCreator(configuration).bINLookup(hostedPagesId, iINLookupRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaticAssetsBINLookupApi - factory interface
 * @export
 */
export const StaticAssetsBINLookupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Performs a BIN lookup in the backend
         * @param {string} hostedPagesId 
         * @param {IINLookupRequest} iINLookupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bINLookup(hostedPagesId: string, iINLookupRequest: IINLookupRequest, options?: any): AxiosPromise<Array<Method>> {
            return StaticAssetsBINLookupApiFp(configuration).bINLookup(hostedPagesId, iINLookupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticAssetsBINLookupApi - object-oriented interface
 * @export
 * @class StaticAssetsBINLookupApi
 * @extends {BaseAPI}
 */
export class StaticAssetsBINLookupApi extends BaseAPI {
    /**
     * 
     * @summary Performs a BIN lookup in the backend
     * @param {string} hostedPagesId 
     * @param {IINLookupRequest} iINLookupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsBINLookupApi
     */
    public bINLookup(hostedPagesId: string, iINLookupRequest: IINLookupRequest, options?: any) {
        return StaticAssetsBINLookupApiFp(this.configuration).bINLookup(hostedPagesId, iINLookupRequest, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * StaticAssetsGetLogoApi - axios parameter creator
 * @export
 */
export const StaticAssetsGetLogoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the logo of a method
         * @param {string} method 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogo: async (method: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'method' is not null or undefined
            if (method === null || method === undefined) {
                throw new RequiredError('method','Required parameter method was null or undefined when calling getLogo.');
            }
            const localVarPath = `/static-assets/logo/{method}`
                .replace(`{${"method"}}`, encodeURIComponent(String(method)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticAssetsGetLogoApi - functional programming interface
 * @export
 */
export const StaticAssetsGetLogoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the logo of a method
         * @param {string} method 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogo(method: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await StaticAssetsGetLogoApiAxiosParamCreator(configuration).getLogo(method, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaticAssetsGetLogoApi - factory interface
 * @export
 */
export const StaticAssetsGetLogoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the logo of a method
         * @param {string} method 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogo(method: string, options?: any): AxiosPromise<string> {
            return StaticAssetsGetLogoApiFp(configuration).getLogo(method, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticAssetsGetLogoApi - object-oriented interface
 * @export
 * @class StaticAssetsGetLogoApi
 * @extends {BaseAPI}
 */
export class StaticAssetsGetLogoApi extends BaseAPI {
    /**
     * 
     * @summary Gets the logo of a method
     * @param {string} method 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsGetLogoApi
     */
    public getLogo(method: string, options?: any) {
        return StaticAssetsGetLogoApiFp(this.configuration).getLogo(method, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * StaticAssetsGetStateApi - axios parameter creator
 * @export
 */
export const StaticAssetsGetStateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the current session state
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getState: async (hostedPagesId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostedPagesId' is not null or undefined
            if (hostedPagesId === null || hostedPagesId === undefined) {
                throw new RequiredError('hostedPagesId','Required parameter hostedPagesId was null or undefined when calling getState.');
            }
            const localVarPath = `/get-state/{hosted-pages-id}`
                .replace(`{${"hosted-pages-id"}}`, encodeURIComponent(String(hostedPagesId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticAssetsGetStateApi - functional programming interface
 * @export
 */
export const StaticAssetsGetStateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the current session state
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getState(hostedPagesId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponse>> {
            const localVarAxiosArgs = await StaticAssetsGetStateApiAxiosParamCreator(configuration).getState(hostedPagesId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaticAssetsGetStateApi - factory interface
 * @export
 */
export const StaticAssetsGetStateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the current session state
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getState(hostedPagesId: string, options?: any): AxiosPromise<PageResponse> {
            return StaticAssetsGetStateApiFp(configuration).getState(hostedPagesId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticAssetsGetStateApi - object-oriented interface
 * @export
 * @class StaticAssetsGetStateApi
 * @extends {BaseAPI}
 */
export class StaticAssetsGetStateApi extends BaseAPI {
    /**
     * 
     * @summary Gets the current session state
     * @param {string} hostedPagesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsGetStateApi
     */
    public getState(hostedPagesId: string, options?: any) {
        return StaticAssetsGetStateApiFp(this.configuration).getState(hostedPagesId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * StaticAssetsUpdateStateApi - axios parameter creator
 * @export
 */
export const StaticAssetsUpdateStateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates the session state with the provided details.
         * @param {string} hostedPagesId 
         * @param {ProvideDetailsRequest} provideDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provideDetails: async (hostedPagesId: string, provideDetailsRequest: ProvideDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostedPagesId' is not null or undefined
            if (hostedPagesId === null || hostedPagesId === undefined) {
                throw new RequiredError('hostedPagesId','Required parameter hostedPagesId was null or undefined when calling provideDetails.');
            }
            // verify required parameter 'provideDetailsRequest' is not null or undefined
            if (provideDetailsRequest === null || provideDetailsRequest === undefined) {
                throw new RequiredError('provideDetailsRequest','Required parameter provideDetailsRequest was null or undefined when calling provideDetails.');
            }
            const localVarPath = `/update-state/{hosted-pages-id}/provide-details`
                .replace(`{${"hosted-pages-id"}}`, encodeURIComponent(String(hostedPagesId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof provideDetailsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(provideDetailsRequest !== undefined ? provideDetailsRequest : {}) : (provideDetailsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restarts the session, transitioning the Static Assets back to the start
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restart: async (hostedPagesId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostedPagesId' is not null or undefined
            if (hostedPagesId === null || hostedPagesId === undefined) {
                throw new RequiredError('hostedPagesId','Required parameter hostedPagesId was null or undefined when calling restart.');
            }
            const localVarPath = `/update-state/{hosted-pages-id}/restart`
                .replace(`{${"hosted-pages-id"}}`, encodeURIComponent(String(hostedPagesId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint to be redirected back towards by third parties
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnFromThirdPartyGet: async (hostedPagesId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostedPagesId' is not null or undefined
            if (hostedPagesId === null || hostedPagesId === undefined) {
                throw new RequiredError('hostedPagesId','Required parameter hostedPagesId was null or undefined when calling returnFromThirdPartyGet.');
            }
            const localVarPath = `/update-state/{hosted-pages-id}/return-from-third-party`
                .replace(`{${"hosted-pages-id"}}`, encodeURIComponent(String(hostedPagesId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint to be redirected back towards by third parties
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnFromThirdPartyPost: async (hostedPagesId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostedPagesId' is not null or undefined
            if (hostedPagesId === null || hostedPagesId === undefined) {
                throw new RequiredError('hostedPagesId','Required parameter hostedPagesId was null or undefined when calling returnFromThirdPartyPost.');
            }
            const localVarPath = `/update-state/{hosted-pages-id}/return-from-third-party`
                .replace(`{${"hosted-pages-id"}}`, encodeURIComponent(String(hostedPagesId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the session state with the selected method
         * @param {string} hostedPagesId 
         * @param {SelectMethodRequest} selectMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectMethod: async (hostedPagesId: string, selectMethodRequest: SelectMethodRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hostedPagesId' is not null or undefined
            if (hostedPagesId === null || hostedPagesId === undefined) {
                throw new RequiredError('hostedPagesId','Required parameter hostedPagesId was null or undefined when calling selectMethod.');
            }
            // verify required parameter 'selectMethodRequest' is not null or undefined
            if (selectMethodRequest === null || selectMethodRequest === undefined) {
                throw new RequiredError('selectMethodRequest','Required parameter selectMethodRequest was null or undefined when calling selectMethod.');
            }
            const localVarPath = `/update-state/{hosted-pages-id}/select-method`
                .replace(`{${"hosted-pages-id"}}`, encodeURIComponent(String(hostedPagesId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof selectMethodRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(selectMethodRequest !== undefined ? selectMethodRequest : {}) : (selectMethodRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticAssetsUpdateStateApi - functional programming interface
 * @export
 */
export const StaticAssetsUpdateStateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates the session state with the provided details.
         * @param {string} hostedPagesId 
         * @param {ProvideDetailsRequest} provideDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async provideDetails(hostedPagesId: string, provideDetailsRequest: ProvideDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponse>> {
            const localVarAxiosArgs = await StaticAssetsUpdateStateApiAxiosParamCreator(configuration).provideDetails(hostedPagesId, provideDetailsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Restarts the session, transitioning the Static Assets back to the start
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restart(hostedPagesId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponse>> {
            const localVarAxiosArgs = await StaticAssetsUpdateStateApiAxiosParamCreator(configuration).restart(hostedPagesId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Endpoint to be redirected back towards by third parties
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnFromThirdPartyGet(hostedPagesId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StaticAssetsUpdateStateApiAxiosParamCreator(configuration).returnFromThirdPartyGet(hostedPagesId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Endpoint to be redirected back towards by third parties
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnFromThirdPartyPost(hostedPagesId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StaticAssetsUpdateStateApiAxiosParamCreator(configuration).returnFromThirdPartyPost(hostedPagesId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates the session state with the selected method
         * @param {string} hostedPagesId 
         * @param {SelectMethodRequest} selectMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectMethod(hostedPagesId: string, selectMethodRequest: SelectMethodRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponse>> {
            const localVarAxiosArgs = await StaticAssetsUpdateStateApiAxiosParamCreator(configuration).selectMethod(hostedPagesId, selectMethodRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaticAssetsUpdateStateApi - factory interface
 * @export
 */
export const StaticAssetsUpdateStateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Updates the session state with the provided details.
         * @param {string} hostedPagesId 
         * @param {ProvideDetailsRequest} provideDetailsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provideDetails(hostedPagesId: string, provideDetailsRequest: ProvideDetailsRequest, options?: any): AxiosPromise<PageResponse> {
            return StaticAssetsUpdateStateApiFp(configuration).provideDetails(hostedPagesId, provideDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restarts the session, transitioning the Static Assets back to the start
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restart(hostedPagesId: string, options?: any): AxiosPromise<PageResponse> {
            return StaticAssetsUpdateStateApiFp(configuration).restart(hostedPagesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint to be redirected back towards by third parties
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnFromThirdPartyGet(hostedPagesId: string, options?: any): AxiosPromise<void> {
            return StaticAssetsUpdateStateApiFp(configuration).returnFromThirdPartyGet(hostedPagesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint to be redirected back towards by third parties
         * @param {string} hostedPagesId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnFromThirdPartyPost(hostedPagesId: string, options?: any): AxiosPromise<void> {
            return StaticAssetsUpdateStateApiFp(configuration).returnFromThirdPartyPost(hostedPagesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the session state with the selected method
         * @param {string} hostedPagesId 
         * @param {SelectMethodRequest} selectMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectMethod(hostedPagesId: string, selectMethodRequest: SelectMethodRequest, options?: any): AxiosPromise<PageResponse> {
            return StaticAssetsUpdateStateApiFp(configuration).selectMethod(hostedPagesId, selectMethodRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticAssetsUpdateStateApi - object-oriented interface
 * @export
 * @class StaticAssetsUpdateStateApi
 * @extends {BaseAPI}
 */
export class StaticAssetsUpdateStateApi extends BaseAPI {
    /**
     * 
     * @summary Updates the session state with the provided details.
     * @param {string} hostedPagesId 
     * @param {ProvideDetailsRequest} provideDetailsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsUpdateStateApi
     */
    public provideDetails(hostedPagesId: string, provideDetailsRequest: ProvideDetailsRequest, options?: any) {
        return StaticAssetsUpdateStateApiFp(this.configuration).provideDetails(hostedPagesId, provideDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restarts the session, transitioning the Static Assets back to the start
     * @param {string} hostedPagesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsUpdateStateApi
     */
    public restart(hostedPagesId: string, options?: any) {
        return StaticAssetsUpdateStateApiFp(this.configuration).restart(hostedPagesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint to be redirected back towards by third parties
     * @param {string} hostedPagesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsUpdateStateApi
     */
    public returnFromThirdPartyGet(hostedPagesId: string, options?: any) {
        return StaticAssetsUpdateStateApiFp(this.configuration).returnFromThirdPartyGet(hostedPagesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint to be redirected back towards by third parties
     * @param {string} hostedPagesId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsUpdateStateApi
     */
    public returnFromThirdPartyPost(hostedPagesId: string, options?: any) {
        return StaticAssetsUpdateStateApiFp(this.configuration).returnFromThirdPartyPost(hostedPagesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the session state with the selected method
     * @param {string} hostedPagesId 
     * @param {SelectMethodRequest} selectMethodRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticAssetsUpdateStateApi
     */
    public selectMethod(hostedPagesId: string, selectMethodRequest: SelectMethodRequest, options?: any) {
        return StaticAssetsUpdateStateApiFp(this.configuration).selectMethod(hostedPagesId, selectMethodRequest, options).then((request) => request(this.axios, this.basePath));
    }

}


