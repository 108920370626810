import { ErrorTypes } from "../../api/client";

export default {
  translation: {
    amount: "Amount",
    paymentMethod: "Payment method",
    choosePayment: "Choose payment method",
    cardHolderName: "Card Holder Name",
    brand: "Card brand",
    cardNumber: "Card Number",
    securityCode: "Security Code",
    expiryMonth: "Expiry month",
    expiryYear: "Expiry year",
    expiryDate: "Expiry date",
    "Required field": "Required field",
    "Input is too long": "Input is too long",
    "Input is too short": "Input is too short",
    "The expiry date is incorrect": "The expiry date is incorrect",
    "The date should be in the future": "The date should be in the future",
    "You can retry by clicking below": "You can retry by clicking below",
    Reset: "Reset",
    Cancel: "Cancel",
    Submit: "Submit",
    "Session expired": "Session Expired",
    "Merchant failed to process the payment request":
      "Merchant failed to process the payment request",
    "Your payment was successful": "Your payment was successful",
    "No methods available for selection": "No methods available for selection",
    "500": "Something went wrong",
    "404": "Session Expired",
    "400": "Please complete the form and try again",
    [ErrorTypes.CardNumber]: "Invalid Card Number",
    [ErrorTypes.CardBrand]: "Invalid Card Brand",
    [ErrorTypes.ExpiryDate]: "Invalid expiry date",
    [ErrorTypes.Cvv]: "Invalid CVV/CVC",
    [ErrorTypes.CardHolderName]: "Invalid Card holder name",
    Redirect: "Redirect",
    "You will be redirected shortly": "You will be redirected shortly",
    "If this does not work click below": "If this does not work click below",
    "MM/YY": "MM/YY",
    "No method has been selected on the select method page":
      "No method has been selected on the select method page",
    "No year found that matches the key provided":
      "No year found that matches the key provided",
  },
};
