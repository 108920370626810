import { NativeSelect } from "@material-ui/core";
import { Control } from "react-hook-form";
import React, { PropsWithChildren, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core";
import i18n from "i18next";

type PropsType = {
  initialLanguage: string;
  control: Control;
};

const useStyles = makeStyles({
  root: {
    width: "150px",
    fontSize: 14,
    fontFamily: "'Quicksand', 'Helvetica', sans-serif",
    color: "#979797",
  },
});

function LanguageSwitch({ initialLanguage }: PropsWithChildren<PropsType>) {
  const classes = useStyles();
  const [language, setLanguage] = React.useState(initialLanguage as string);

  useEffect(() => {
    i18n.changeLanguage(initialLanguage);
  }, [initialLanguage]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as string);
    i18n.changeLanguage(event.target.value as string);
  };

  return (
    <FormControl>
      <NativeSelect
        className={classes.root}
        value={language}
        onChange={handleChange}
        inputProps={{
          name: "languageSelect",
          id: "languageSelect",
        }}
      >
        <option value={"en-US"}>English</option>
        <option value={"fr-FR"}>Français</option>
        <option value={"nl-NL"}>Nederlands</option>
      </NativeSelect>
    </FormControl>
  );
}

export default LanguageSwitch;
