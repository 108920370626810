import { FormHelperText } from "@material-ui/core";
import React from "react";
import { FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FormFieldType } from "../types/formField";

type Props = {
  formField: FormFieldType;
  errors: FieldErrors;
};

function ErrorText({ formField, errors = [] }: Props) {
  if (errors) {
    return (
      <ErrorMessage
        name={formField.name}
        errors={errors}
        render={({ message }) => (
          <FormHelperText id={`errorMessage${formField.name}`} error>
            {message}
          </FormHelperText>
        )}
      />
    );
  }

  return null;
}

export default ErrorText;
