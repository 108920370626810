import React from "react";
import { queryCache, ReactQueryCacheProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Layout from "./components/Layout";
import ErrorFallback from "./components/ErrorFallback";
import "./i18n/translate";
import { Container, Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <BrowserRouter>
        <CssBaseline />

        <Container fixed={false} maxWidth={false}>
          <Grid container item alignItems="center" direction="column">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Layout />
            </ErrorBoundary>
          </Grid>
        </Container>
      </BrowserRouter>
    </ReactQueryCacheProvider>
  );
}

export default App;
