import { useMutation, useQuery, useQueryCache } from "react-query";
import getData from "../utils/getDataFromAxiosResponse";
import api from "../api";
import selectMethod from "../mutations/select-method";
import provideDetails from "../mutations/provide-details";
import restartMutation from "../mutations/restart";
import bINLookup from "../mutations/iin-lookup";

function usePageResponse(hostedPagesId: string) {
  const queryConfig = { refetchOnWindowFocus: false, refetchOnMount: false };
  const queryCache = useQueryCache();
  return {
    pageResponse: useQuery(
      ["page-response", hostedPagesId],
      () => getData(api.getState(hostedPagesId)),
      queryConfig
    ),
    selectMethod: useMutation(selectMethod, {
      onSuccess: (data) =>
        queryCache.setQueryData(["page-response", hostedPagesId], data),
    }),
    provideDetails: useMutation(provideDetails, {
      onSuccess: (data) =>
        queryCache.setQueryData(["page-response", hostedPagesId], data),
    }),
    restart: useMutation(restartMutation, {
      onSuccess: (data) =>
        queryCache.setQueryData(["page-response", hostedPagesId], data),
    }),
    bINLookup: useMutation(bINLookup),
  };
}

export default usePageResponse;
