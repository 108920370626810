import React from "react";

type PageProps = {};
type PagesProps = {
  activePage: string | undefined;
  children: React.ReactElement<PageProps>[];
};

const Pages = ({ activePage, children }: PagesProps) => {
  if (!activePage) {
    throw new Error("No active page specified");
  }

  return <>{children.find((child) => child.key === activePage)}</>;
};

export default Pages;
